<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!booking">
      <span class="loader"></span>
    </span>
    <div v-if="booking" class="elite-tabs-wrapper">
      <div class="view-details-header">
        <div class="view-details-header-left">
          <h2>{{ $t("BOOKINGS.BOOKING_DETAILS") }}</h2>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("BOOKINGS.BOOKINGS_LIST") }}</span>
              </li>
              <li>
                <span>{{ booking.code }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="view-details-header-right">
          <button class="edit" @click="checkInBooking" v-if="canCheckInBooking">
            <!-- <i class="far fa-trash-alt"></i> -->
            <span>{{ $t("BOOKINGS.CHECK_IN") }}</span>
          </button>
          <button
            class="edit"
            @click="checkOutBooking"
            v-if="canCheckOutBooking"
          >
            <!-- <i class="far fa-edit"></i> -->
            <span>{{ $t("BOOKINGS.CHECK_OUT") }}</span>
          </button>
        </div>
      </div>
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="booking"
      >
        <tab-pane title="booking" id="1" :active="true">
          <span slot="title">
            <!-- <i class="ni ni-cloud-upload-96"></i> -->
            {{ $t("COMMON.SUMMARY") }}
          </span>
          <booking-view-global :booking="booking" @onContractUpdated="get" />
        </tab-pane>
        <tab-pane title="spot" id="2" :active="true">
          <span slot="title">
            {{ $t("COMMON.SPOT") }}
          </span>
          <booking-view-spot :booking="booking" />
        </tab-pane>
        <tab-pane title="customer" id="3" :active="true">
          <span slot="title">
            {{ $t("COMMON.CUSTOMER") }}
          </span>
          <booking-view-customer :booking="booking" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import BookingViewGlobal from "../partials/BookingViewGlobal.vue";
import BookingViewSpot from "../partials/BookingViewSpot.vue";
import BookingViewCustomer from "../partials/BookingViewCustomer.vue";
import {
  BOOKING_STATUS_ARRIVED,
  BOOKING_STATUS_CONFIRMED,
} from "../../../../../constants/common";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    BookingViewGlobal,
    BookingViewSpot,
    BookingViewCustomer,
  },

  mixins: [],

  props: {
    bookingId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      booking: null,
    };
  },

  computed: {
    canCheckInBooking() {
      return (
        !!this.booking &&
        [BOOKING_STATUS_CONFIRMED].includes(this.booking?.status)
      );
    },

    canCheckOutBooking() {
      return (
        !!this.booking && [BOOKING_STATUS_ARRIVED].includes(this.booking.status)
      );
    },
  },

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("bookings/get", this.bookingId);
        this.booking = this.$store.getters["bookings/booking"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    checkInBooking() {
      this.$emit("onCheckInBooking", this.booking);
    },

    checkOutBooking() {
      this.$emit("onCheckOutBooking", this.booking);
    },
  },
};
</script>
