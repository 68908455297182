<template>
  <div class="step-body step-1">
    <!-- start_at, end_at -->
    <div class="form-wrapper width_1">
      <base-input class="form-wrapper full">
        <el-date-picker
          v-model="selectedDateRange"
          type="daterange"
          start-placeholder="Date de début"
          end-placeholder="Date de fin"
          format="dd MMM yyyy"
          value-format="yyyy-MM-dd"
          :min="new Date()"
          :clearable="false"
          :picker-options="{ disabledDate: isDisabledDate }"
          @change="onDateSelected"
        />
      </base-input>
      <validation-error
        v-if="isSpotUnavailable"
        :errors="[$t('BOOKINGS.NOT_AVAILABLE_ERROR')]"
      />
      <validation-error :errors="apiValidationErrors.start_at" />
    </div>

    <!-- Organization -->
    <div
      class="form-wrapper width_1"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <base-input
        :label="`${$t('COMMON.ORGANIZATION')} (*)`"
        :placeholder="$t('COMMON.ORGANIZATION')"
      >
        <organization-selector
          :organization="booking.organization?.id"
          :filterable="true"
          :showAll="false"
          @organizationChanged="
            (organizationId) => {
              booking.organization.id = organizationId;
              onFormChanged();
            }
          "
          disabled
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.organization" />
    </div>

    <!-- adult capacity -->
    <div class="form-wrapper width_1-3">
      <base-input :label="`${$t('SPOTS.SPOT_ADULT_CAPACITY')}`">
        <el-select
          :label="$t('SPOTS.SPOT_ADULT_CAPACITY')"
          :placeholder="$t('SPOTS.SPOT_ADULT_CAPACITY')"
          v-model="booking.adults_count"
          @change="
            (capacity) => {
              booking.adults_count = capacity;
              onFormChanged();
            }
          "
        >
          <el-option
            v-for="n in Array.from({ length: maxAdults }, (_, i) => i + 1)"
            :key="n"
            :value="n"
            :label="n"
          />
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.adults_count" />
    </div>

    <!-- children capacity -->
    <div class="form-wrapper width_1-3">
      <base-input :label="`${$t('SPOTS.SPOT_CHILDREN_CAPACITY')}`">
        <el-select
          :label="$t('SPOTS.SPOT_CHILDREN_CAPACITY')"
          :placeholder="$t('SPOTS.SPOT_CHILDREN_CAPACITY')"
          v-model="booking.children_count"
          @change="onFormChanged"
        >
          <el-option
            v-for="n in maxChildren + 1"
            :key="n"
            :value="n - 1"
            :label="n - 1"
          />
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.children_count" />
    </div>

    <!-- pets capacity -->
    <div class="form-wrapper width_1-3">
      <base-input :label="`${$t('SPOTS.SPOT_PETS_CAPACITY')}`">
        <el-select
          :label="$t('SPOTS.SPOT_PETS_CAPACITY')"
          :placeholder="$t('SPOTS.SPOT_PETS_CAPACITY')"
          v-model="booking.pets_count"
          @change="onFormChanged"
        >
          <el-option
            v-for="n in maxPets + 1"
            :key="n"
            :value="n - 1"
            :label="n - 1"
          />
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.pets_count" />
    </div>

    <div class="form-wrapper width_1">
      <label class="pers-count">
        {{ $t("BOOKINGS.CAPACITY") }} :
        {{ booking.adults_count + booking.children_count }} pers
      </label>
    </div>

    <!-- beds qty -->
    <div class="form-wrapper width_1" v-if="shouldIDisplayBedsQuantityField">
      <base-input :label="`${$t('SPOTS.BEDS_TOTAL_QTY')}`">
        <el-select
          :label="$t('SPOTS.BEDS_TOTAL_QTY')"
          :placeholder="$t('SPOTS.BEDS_TOTAL_QTY')"
          v-model="booking.beds_qty"
          @change="onFormChanged"
        >
          <el-option
            v-for="n in spot.beds_total_qty + 1"
            :key="n"
            :value="n - 1"
            :label="n - 1"
          />
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.beds_qty" />
    </div>

    <!-- rooms qty -->
    <div
      class="form-wrapper width_1"
      v-if="spot.category.booking_form_type == BOOKING_FORM_TYPE_PROPERTY"
    >
      <base-input
        :label="`${$t('SPOTS.ROOMS_QTY')}`"
        :placeholder="$t('SPOTS.ROOMS_QTY')"
        type="number"
        :max="10"
        :min="0"
        v-model="booking.rooms_qty"
        @change="onFormChanged"
      >
      </base-input>

      <validation-error :errors="apiValidationErrors.rooms_qty" />
    </div>

    <div class="form-wrapper width_1">
      <base-input :label="`${$t('SPOTS.CHANGE_RATES')}`">
        <el-select
          :label="$t('SPOTS.CHANGE_RATES')"
          :placeholder="$t('SPOTS.CHANGE_RATES')"
          v-model="booking.rate"
          @change="onFormChanged"
        >
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.rate" />
    </div>

    <div
      class="form-wrapper width_1"
      v-if="isLandOrSeasonalFormType"
    >
      <base-input :label="`${$t('SPOTS.EQUIPMENT_SELECTION')}`">
        <allowed-equipment-selector
          :value="booking.equipment"
          :options-value="spot.allowed_equipment"
          @valueChanged="(value) => (booking.equipment = value)"
          allowNone
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.equipment" />
    </div>

    <!-- equipment length -->
    <div
      class="form-wrapper width_1"
      v-if="
        isLandOrSeasonalFormType &&
        booking.equipment &&
        booking.equipment != TENT
      "
    >
      <base-input
        type="text"
        :min="0"
        :max="maxEquipmentLength"
        :label="$t('SPOTS.EQUIPMENT_LENGTH')"
        :placeholder="$t('SPOTS.EQUIPMENT_LENGTH')"
        :name="`'${$t('SPOTS.EQUIPMENT_LENGTH')}'`"
        :rules="equipmentLengthValidationRules"
        v-model="booking.equipment_length"
        @change="onFormChanged"
      >
        <template #label>
          <label class="form-control-label" for="">
            {{ $t("SPOTS.EQUIPMENT_LENGTH") }}
            <span v-if="maxEquipmentLength" class="h5 text-muted font-italic">
              ({{
                $t("SPOTS.MAX_LENGTH", { length: maxEquipmentLength ?? 0 })
              }})
            </span>
          </label>
        </template>
      </base-input>
      <validation-error :errors="apiValidationErrors.equipment_length" />
    </div>

    <div
      class="form-wrapper width_1-2"
      v-if="isLandOrSeasonalFormType"
    >
      <base-input :label="`${$t('SPOTS.DRIVER_EXTENSION')}`">
        <el-select
          :label="$t('SPOTS.DRIVER_EXTENSION')"
          :placeholder="$t('SPOTS.DRIVER_EXTENSION')"
          v-model="booking.driver_extensions"
          @change="onFormChanged"
        >
          <el-option
            v-for="n in spot.driver_extensions + 1"
            :key="n"
            :value="n - 1"
            :label="n - 1"
          />
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.rate" />
    </div>

    <div
      class="form-wrapper width_1-2"
      v-if="isLandOrSeasonalFormType"
    >
      <base-input :label="`${$t('SPOTS.PASSENGER_EXTENSION')}`">
        <el-select
          :label="$t('SPOTS.PASSENGER_EXTENSION')"
          :placeholder="$t('SPOTS.PASSENGER_EXTENSION')"
          v-model="booking.passenger_extensions"
          @change="onFormChanged"
        >
          <el-option
            v-for="n in spot.passenger_extensions + 1"
            :key="n"
            :value="n - 1"
            :label="n - 1"
          />
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.rate" />
    </div>

    <div
      class="form-wrapper width_1"
      v-if="isLandOrSeasonalFormType && false"
    >
      <base-input :label="`${$t('SPOTS.SERVICES_INCLUDED')}`">
        <service-included-selector
          :options-value="spot.services_included"
          :value="booking.services_included"
          @valueChanged="(value) => (booking.services_included = value)"
          allow-none
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.services_included" />
    </div>

    <div
      class="form-wrapper width_1"
      v-if="isLandOrSeasonalFormType"
    >
      <base-input :label="`${$t('SPOTS.ELECTRICITY')}`">
        <electricity-selector
          :options-value="spot.electricity"
          :value="booking.electricity"
          @valueChanged="(value) => (booking.electricity = value)"
          allow-none
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.electricity" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="$t('BOOKINGS.SPECIAL_REQUEST')"
        :placeholder="$t('BOOKINGS.SPECIAL_REQUEST')"
      >
        <el-input type="textarea" v-model="booking.special_request"></el-input>
      </base-input>
      <validation-error :errors="apiValidationErrors.special_request" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="$t('BOOKINGS.INTERNAL_NOTE')"
        :placeholder="$t('BOOKINGS.INTERNAL_NOTE')"
      >
        <el-input type="textarea" v-model="booking.comment"></el-input>
      </base-input>
      <validation-error :errors="apiValidationErrors.comment" />
    </div>

    <div class="form-wrapper width_1 summary">
      <label class="pers-count"> {{ $t("SPOTS.OPTIONS") }} </label>
      <div class="mt-2">
        <base-checkbox v-model="booking.is_blocked">
          {{ $t("BOOKINGS.BLOCK_THIS_SPOT") }}
        </base-checkbox>
      </div>
    </div>

    <div class="form-wrapper width_1 summary" v-if="false">
      <dl class="row">
        <dt>{{ spot.unit_price }} CAD (x {{ this.pricing.duration }} jours)</dt>
        <dd>{{ pricing.subTotalPrice }} CAD</dd>
      </dl>
      <dl class="row">
        <dt>TVS</dt>
        <dd>{{ pricing.tps?.toFixed(2) }} CAD</dd>
      </dl>
      <dl class="row">
        <dt>TVQ</dt>
        <dd>{{ pricing.tvq?.toFixed(2) }} CAD</dd>
      </dl>
      <dl class="row total">
        <dt>Total</dt>
        <dd>{{ pricing.totalPrice?.toFixed(2) }} CAD</dd>
      </dl>
    </div>

    <div class="form-wrapper width_1 buttons-group">
      <el-button
        :disabled="isSpotUnavailable"
        class="continue"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t("COMMON.CONTINUE") }}
      </el-button>
      <!-- <el-button class="outline" type="secondary" @click="handleSubmit">
        {{ $t("COMMON.SAVE_FOR_LATER") }}
      </el-button> -->
    </div>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Header,
  Image,
  Row,
  Step,
  Steps,
  Select,
  Option,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import "flatpickr/dist/flatpickr.css";
import ValidationError from "@/components/ValidationError";
import OrganizationSelector from "@/components/OrganizationSelector";
// import EquipmentSelector from "@/components/EquipmentSelector";
import defaultBooking from "../../defaultBooking";
import defaultSpot from "../../../../SpotModule/SpotManagement/defaultSpot";
import ServiceIncludedSelector from "@/components/ServiceIncludedSelector.vue";
import AllowedEquipmentSelector from "@/components/AllowedEquipmentSelector.vue";
import ElectricitySelector from "@/components/ElectricitySelector.vue";
import {
  BOOKING_FORM_TYPE_LAND,
  BOOKING_FORM_TYPE_PROPERTY,
  BOOKING_FORM_TYPE_READY_TO_CAMP,
  BOOKING_FORM_TYPE_SEASONAL,
} from "@/constants/spotCategories";
import { TENT } from "@/constants/allowedEquipments";
import swal from "sweetalert2";
import { SPOT_STATUS_NOT_AVAILABLE } from "@/constants/common";

export default {
  name: "booking-spot-selection",

  components: {
    OrganizationSelector,
    ValidationError,
    // EquipmentSelector,
    ServiceIncludedSelector,
    ElectricitySelector,
    AllowedEquipmentSelector,
    [Header.name]: Header,
    [Divider.name]: Divider,
    [Row.name]: Row,
    [Col.name]: Col,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Button.name]: Button,
    [Image.name]: Image,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: {
    bookingData: {
      type: Object,
      default: defaultBooking,
      description: "Booking object",
    },
    spot: {
      type: Object,
      default: defaultSpot,
      description: "Spot object",
    },
  },

  data() {
    this.fetchBookingList();

    let selectedDateRange = [];

    if (this.bookingData.start_at) {
      selectedDateRange = [
        this.parseBookingDate(this.bookingData.start_at),
        this.parseBookingDate(this.bookingData.end_at),
      ];
    }

    this.bookingData.adults_count =
      this.bookingData.adults_count <= 0 ? 1 : this.bookingData.adults_count;

    const maxEquipmentLength = this.spot.equipment_length ?? 10;
    const equipmentLengthValidationRules = `numeric|min_value:0|max_value:${maxEquipmentLength}`;

    return {
      booking: this.bookingData,
      selectedDateRange: selectedDateRange,
      pricing: {
        duration: null,
        subTotalPrice: null,
        tps: null,
        tvq: null,
        totalPrice: null,
      },
      bookingList: [],
      maxAdults: 10,
      maxChildren: 10,
      maxPets: 10,
      maxEquipmentLength,
      showModal: false,
      showBookingCheckModal: false,
      loading: false,
      formErrors: null,
      BOOKING_FORM_TYPE_LAND,
      BOOKING_FORM_TYPE_PROPERTY,
      TENT,
      equipmentLengthValidationRules: equipmentLengthValidationRules,
    };
  },

  created() {},

  mounted() {},

  computed: {
    shouldIDisplayBedsQuantityField() {
      return (
        this.spot.category.booking_form_type !== BOOKING_FORM_TYPE_LAND &&
        this.spot.category.booking_form_type !==
          BOOKING_FORM_TYPE_READY_TO_CAMP &&
        this.spot.category.booking_form_type !== BOOKING_FORM_TYPE_SEASONAL
      );
    },
    isSeasonalFormType() {
      return this.spot.category.booking_form_type === BOOKING_FORM_TYPE_SEASONAL
    },
    isLandFormType() {
      return this.spot.category.booking_form_type === BOOKING_FORM_TYPE_LAND
    },
    isLandOrSeasonalFormType() {
      return this.isLandFormType || this.isSeasonalFormType ;
    },
    isSpotUnavailable() {
      return this.spot.status === SPOT_STATUS_NOT_AVAILABLE;
    },
  },

  methods: {
    async handleSubmit() {
      if (this.isSpotUnavailable) {
        return;
      }
      if (!this.spot.pricing.total) {
        this.$notify({
          type: "danger",
          message: this.$t("SPOTS.NO_PRICING_FOUND_FOR_SPOT"),
        });

        return;
      }
      await this.sendInformation();
    },

    async sendInformation() {
      this.loading = true;

      swal.showLoading();

      const bookingData = cloneDeep(this.booking);
      if (!bookingData.customer?.id) {
        delete bookingData.customer;
      }

      if (!bookingData.spot?.id) {
        bookingData.spot.id = this.spot.id;
      }

      delete bookingData.order;

      try {
        if (bookingData.id) {
          delete bookingData.createdBy;
          delete bookingData.orderItem;

          await this.$store.dispatch("bookings/update", bookingData);
        } else {
          await this.$store.dispatch("bookings/add", bookingData);
        }

        const createdBooking = await this.$store.getters["bookings/booking"];
        this.booking.id = createdBooking.id;

        this.$emit("draftBookingCreated", createdBooking);
        swal.close();
      } catch (error) {
        swal.close();
        this.formErrors = error.response.data.errors;
      }
      this.loading = false;
    },

    async fetchBookingList() {
      if (!this.spot) {
        return false;
      }

      try {
        this.loading = true;
        const today = new Date();
        const afterThreeMonths = new Date();
        afterThreeMonths.setMonth(today.getMonth() + 3);
        let params = {
          filter: {
            ...{
              spot_id_in: [this.spot.id],
              date_range: [today, afterThreeMonths],
            },
          },
          page: {
            number: 1,
            size: 999,
          },
        };

        if (this.booking?.organization?.id) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.booking?.organization?.id,
            },
          };
        }
        await this.$store.dispatch("bookings/list", params);
        this.bookingList = this.$store.getters["bookings/list"];
        this.loading = false;
      } catch (error) {
        console.warn(error);
        this.loading = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    isDisabledDate(date) {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);

      let disabled = false;
      this.bookingList?.forEach((booking) => {
        const startAt = this.parseBookingDate(booking.start_at);
        const endAt = this.parseBookingDate(booking.end_at);

        if ((date <= endAt && date >= startAt) || date < new Date()) {
          disabled = true;

          return disabled;
        }
      });

      return disabled;
    },

    parseBookingDate(d) {
      let date = new Date(d);

      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);

      return date;
    },

    calculatePricing(startAt, endAt) {
      const ONE_DAY = 1000 * 60 * 60 * 24;
      const differenceMs = Math.abs(startAt - endAt);
      this.pricing.duration = Math.round(differenceMs / ONE_DAY);
      this.pricing.subTotalPrice = this.spot.unit_price * this.pricing.duration;
      this.pricing.tps = this.pricing.subTotalPrice * 0.05;
      this.pricing.tvq = this.pricing.subTotalPrice * 0.09975;
      this.pricing.totalPrice =
        this.pricing.subTotalPrice + this.pricing.tps + this.pricing.tvq;
    },

    onFormChanged() {
      this.$emit("onFormChanged", this.booking);
    },
    onDateSelected(range) {
      this.booking.start_at = range[0];
      this.booking.end_at = range[1];
      this.onFormChanged();
    },

    async toggleModalState() {
      this.showModal = !this.showModal;
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    selectedDateRange(newDateRange) {
      const startAt = this.parseBookingDate(newDateRange[0]);
      const endAt = this.parseBookingDate(newDateRange[1]);

      const differenceMs = Math.abs(startAt - endAt);

      this.calculatePricing(startAt, endAt);
    },
    bookingData: {
      handler: function (bookingData) {
        if (bookingData) {
          this.booking = {
            ...this.booking,
            ...cloneDeep(bookingData),
          };

          this.booking.adults_count =
            bookingData.adults_count < 2 ? 2 : bookingData.adults_count;
          this.booking.children_count = bookingData.children_count;
          this.booking.pets_count = bookingData.pets_count;
        }
      },
      deep: true,
    },
    spot: {
      handler: function (spot) {
        if (spot) {
          this.fetchBookingList();

          this.maxAdults = spot.adults_capacity;
          this.maxChildren = spot.children_capacity;
          this.maxPets = spot.pets_capacity;
          this.maxEquipmentLength = spot.equipment_length;
          this.equipmentLengthValidationRules = `numeric|min_value:0|max_value:${spot.equipment_length}`;
        }
      },
      deep: true,
    },
  },
};
</script>
