var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-body step-2"},[_c('div',{staticClass:"form-wrapper"},[_c('base-input',[_c('el-radio-group',{model:{value:(_vm.customerSelectionMethod),callback:function ($$v) {_vm.customerSelectionMethod=$$v},expression:"customerSelectionMethod"}},[_c('el-radio',{attrs:{"label":_vm.CUSTOMER_CHOICE_SELECT}},[_vm._v(" "+_vm._s(_vm.$t("BOOKINGS.EXISTING_CUSTOMER"))+" ")]),_c('el-radio',{attrs:{"label":_vm.CUSTOMER_CHOICE_CREATE}},[_vm._v(" "+_vm._s(_vm.$t("BOOKINGS.CREATE_CUSTOMER"))+" ")])],1)],1)],1),(_vm.customerSelectionMethod === _vm.CUSTOMER_CHOICE_SELECT)?_c('div',{staticClass:"form-wrapper"},[_c('base-input',[_c('customer-selector',{attrs:{"customer":_vm.booking.customer?.id,"filterable":true,"showAll":false,"filterOrganization":_vm.booking.organization?.id},on:{"customerChanged":(customerId, customer) => {
            _vm.booking.customer.id = customerId;
            _vm.selectedCustomer = customer;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.customer}})],1):_vm._e(),(_vm.customerSelectionMethod === _vm.CUSTOMER_CHOICE_CREATE)?_c('div',{staticClass:"form-wrapper width_1-2"},[_c('base-input',{attrs:{"label":`${_vm.$t('CUSTOMERS.LAST_NAME')} (*)`,"placeholder":`${_vm.$t('CUSTOMERS.LAST_NAME')}`},model:{value:(_vm.customerModel.lastname),callback:function ($$v) {_vm.$set(_vm.customerModel, "lastname", $$v)},expression:"customerModel.lastname"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.lastname}})],1):_vm._e(),(_vm.customerSelectionMethod === _vm.CUSTOMER_CHOICE_CREATE)?_c('div',{staticClass:"form-wrapper width_1-2"},[_c('base-input',{attrs:{"label":`${_vm.$t('CUSTOMERS.FIRST_NAME')} (*)`,"placeholder":`${_vm.$t('CUSTOMERS.FIRST_NAME')}`},model:{value:(_vm.customerModel.firstname),callback:function ($$v) {_vm.$set(_vm.customerModel, "firstname", $$v)},expression:"customerModel.firstname"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.firstname}})],1):_vm._e(),(_vm.customerSelectionMethod === _vm.CUSTOMER_CHOICE_CREATE)?_c('div',{staticClass:"form-wrapper width_1"},[_c('base-input',{attrs:{"label":`${_vm.$t('CUSTOMERS.EMAIL')} (*)`,"placeholder":`${_vm.$t('CUSTOMERS.EMAIL')}`,"type":"email"},model:{value:(_vm.customerModel.email),callback:function ($$v) {_vm.$set(_vm.customerModel, "email", $$v)},expression:"customerModel.email"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.email}})],1):_vm._e(),(_vm.customerSelectionMethod === _vm.CUSTOMER_CHOICE_CREATE)?_c('div',{staticClass:"form-wrapper width_1 equal-item phone"},[_c('base-input',{attrs:{"label":`${_vm.$t('CUSTOMERS.PHONE')}`,"placeholder":`${_vm.$t('CUSTOMERS.PHONE')}`}},[_c('phone-number-input',{attrs:{"phoneNumber":_vm.customerModel.phone},on:{"phoneNumberChanged":(phone) => {
            _vm.customerModel.phone = phone;
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.phone}})],1):_vm._e(),(_vm.customerSelectionMethod === _vm.CUSTOMER_CHOICE_CREATE)?_c('div',{staticClass:"form-wrapper width_1"},[_c('base-input',{attrs:{"label":_vm.$t('CUSTOMERS.COUNTRY'),"placeholder":_vm.$t('CUSTOMERS.COUNTRY')}},[_c('country-selector',{attrs:{"country":_vm.customerModel.country,"filterable":true,"showAll":false},on:{"countryChanged":(country) => {
            _vm.customerModel.country = country;
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.country}})],1):_vm._e(),(_vm.customerSelectionMethod === _vm.CUSTOMER_CHOICE_CREATE)?_c('div',{staticClass:"form-wrapper width_1"},[_c('base-input',{attrs:{"label":_vm.$t('CUSTOMERS.ADDRESS'),"placeholder":_vm.$t('COMMON.ADDRESS')},model:{value:(_vm.customerModel.address),callback:function ($$v) {_vm.$set(_vm.customerModel, "address", $$v)},expression:"customerModel.address"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.address}})],1):_vm._e(),(_vm.customerSelectionMethod === _vm.CUSTOMER_CHOICE_CREATE)?_c('div',{staticClass:"form-wrapper width_1"},[_c('base-input',{attrs:{"placeholder":_vm.$t('COMMON.CITY')},model:{value:(_vm.customerModel.city),callback:function ($$v) {_vm.$set(_vm.customerModel, "city", $$v)},expression:"customerModel.city"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.city}})],1):_vm._e(),(_vm.customerSelectionMethod === _vm.CUSTOMER_CHOICE_CREATE)?_c('div',{staticClass:"form-wrapper width_1"},[_c('base-input',{attrs:{"placeholder":_vm.$t('COMMON.CITY')},model:{value:(_vm.customerModel.city),callback:function ($$v) {_vm.$set(_vm.customerModel, "city", $$v)},expression:"customerModel.city"}},[_c('state-selector',{attrs:{"country":_vm.customerModel.country,"state":_vm.customerModel.state,"filterable":true,"showAll":false},on:{"stateChanged":(state) => {
            _vm.customerModel.state = state;
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.city}})],1):_vm._e(),(_vm.customerSelectionMethod === _vm.CUSTOMER_CHOICE_CREATE)?_c('div',{staticClass:"form-wrapper width_1"},[_c('base-input',{attrs:{"placeholder":_vm.$t('COMMON.ZIPCODE')},model:{value:(_vm.customerModel.zipcode),callback:function ($$v) {_vm.$set(_vm.customerModel, "zipcode", $$v)},expression:"customerModel.zipcode"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.zipcode}})],1):_vm._e(),(
      _vm.customerSelectionMethod === _vm.CUSTOMER_CHOICE_SELECT && _vm.selectedCustomer
    )?_c('div',{staticClass:"customer-details"},[_c('dl',{staticClass:"row"},[_c('dt',{staticClass:"col-6"},[_vm._v("Nom du client :")]),_c('dd',[_vm._v(" "+_vm._s(_vm.selectedCustomer.firstname + " " + _vm.selectedCustomer.lastname)+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',{staticClass:"col-6"},[_vm._v("Couriel :")]),_c('dd',[_vm._v(" "+_vm._s(_vm.selectedCustomer.email)+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',{staticClass:"col-6"},[_vm._v("Téléphone :")]),_c('dd',[_vm._v(" "+_vm._s(_vm.selectedCustomer.phone)+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',{staticClass:"col-6"},[_vm._v("Pays :")]),_c('dd',[_vm._v(" "+_vm._s(_vm.selectedCustomer.country)+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',{staticClass:"col-6"},[_vm._v("Ville :")]),_c('dd',[_vm._v(" "+_vm._s(_vm.selectedCustomer.city)+" ")])])]):_vm._e(),_c('div',{staticClass:"buttons-group"},[_c('el-button',{staticClass:"continue",attrs:{"type":"primary","disabled":_vm.customerSelectionMethod === _vm.CUSTOMER_CHOICE_SELECT &&
        !_vm.booking.customer.id},on:{"click":() => {
          _vm.handleSubmit(true);
        }}},[_vm._v(" "+_vm._s(_vm.$t("BOOKINGS.SECURE_BOOKING"))+" ")]),_c('el-button',{staticClass:"add",attrs:{"type":"secondary","disabled":_vm.customerSelectionMethod === _vm.CUSTOMER_CHOICE_SELECT &&
        !_vm.booking.customer.id},on:{"click":() => {
          _vm.handleSubmit(false);
        }}},[_vm._v(" "+_vm._s(_vm.editing ? _vm.$t("BOOKINGS.EDIT_BOOKING") : _vm.$t("BOOKINGS.ADD_BOOKING"))+" ")]),_c('el-button',{staticClass:"previous",attrs:{"type":"primary"},on:{"click":() => {
          _vm.gotToStep(0);
        }}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.PREVIOUS"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }