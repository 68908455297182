<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!booking">
      <span class="loader"></span>
    </span>
    <booking-form
      v-if="booking"
      :loading="loading"
      :bookingData="booking"
      :spot="booking?.spot ?? null"
      :formErrors="formErrors"
      :editing="true"
      @bookingSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @bookingUpdated="handleAfterBookingUpdated"
    />
  </div>
</template>

<script>
import BookingForm from "../partials/BookingForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";

export default {
  layout: "DashboardLayout",

  components: { BookingForm },

  mixins: [alertLeave],

  props: {
    bookingId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      booking: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
  
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("bookings/get", this.bookingId);
        this.booking = this.$store.getters["bookings/booking"];

        console.log("this.booking => ",this.booking);
       
      } catch (error) {
        console.log("get.booking.error => ",error);
        
        this.$notify({
          type: "danger",
          message: `Erreur imprévue`,
        });
        
      }finally{
        this.loading = false;
      }
    },

    async handleSubmit(booking) {
      this.loading = true;
      const bookingData = cloneDeep(booking);

      try {
        await this.$store.dispatch("bookings/update", bookingData);
        this.$notify({
          type: "success",
          message: this.$t("BOOKINGS.BOOKING_UPDATED"),
        });
        const booking = await this.$store.getters["bookings/booking"];
        this.$emit("onViewBooking", booking, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    handleAfterBookingUpdated(booking){
      this.$emit("bookingUpdated", booking);
    }
  },
};
</script>
